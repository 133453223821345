<template>
  <v-breadcrumbs :items="items">
    <template v-slot:divider>
      <v-icon icon="mdi-chevron-right"></v-icon>
    </template>
  </v-breadcrumbs>

  <div
    v-if="
      !this.search.isLoading &&
      !this.search.error &&
      (this.search.result?.length > 0 ||
        filter.category != -1 ||
        filter.priceHigh ||
        filter.priceLow ||
        filter.city != '')
    "
    class="d-flex justify-space-between flex-wrap align-center"
  >
    <v-chip-group v-model="filter.category" mandatory>
      <v-chip
        v-for="(item, index) in chips"
        :key="index"
        :value="item.productcategory_id"
        class="mx-2 text-none"
        selected-class="bg-primary"
        variant="outlined"
        rounded="sm"
      >
        {{ item.productcategory_name }}
      </v-chip>
    </v-chip-group>

    <div class="d-flex justify-space-between ma-3">
      <v-btn
        @click="dialog.state = true"
        class="text-none me-2"
        prepend-icon="mdi-tune"
        variant="outlined"
      >
        Filter
      </v-btn>
      <v-select
        v-model="sortSelection"
        class="size-select"
        hide-details
        prepend-inner-icon="mdi-sort-ascending"
        label="Urut berdasarkan"
        density="compact"
        :items="sort"
        item-title="name"
        item-value="state"
        variant="outlined"
      ></v-select>
    </div>
  </div>

  <v-container v-if="this.search.isLoading">
    <div :style="{ height: '80vh' }" class="d-flex justify-center align-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </v-container>

  <div
    v-else-if="
      !this.search.isLoading &&
      !this.search.error &&
      this.search.result?.length > 0
    "
  >
    <v-row justify="start">
      <v-col
        cols="auto"
        v-for="(product, index) in this.search.result"
        :key="index"
      >
        <event-card :product="product"></event-card>
        <non-event-card :product="product"></non-event-card>
        <upcoming-card v-if="false" :product="product"></upcoming-card>
        <fun-card v-if="false" :product="product"></fun-card>
        <workshop-card v-if="false" :product="product"></workshop-card>
      </v-col>
    </v-row>

    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-pagination
          v-model="this.searchData.page"
          :length="paginationLength"
        ></v-pagination>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </div>

  <div
    v-else-if="
      this.search.result?.length > 0 ||
      filter.category != -1 ||
      filter.priceHigh ||
      filter.priceLow ||
      filter.city != ''
    "
    class="d-flex justify-center"
  >
    <v-sheet width="350" class="bg-color">
      <v-img
        @contextmenu.prevent
        src="@/assets/04_ilustration_no_product_found.svg"
        lazy-src="@/assets/04_ilustration_no_product_found.svg"
        width="400"
      ></v-img>
      <p class="text-h6 font-weight-bold text-center">
        Maaf, Kami Tidak Menemukan Yang Kamu Cari
      </p>
      <p class="text-body-2 text-center">
        Silahkan sesuaikan penggunaan filter kamu
      </p>
    </v-sheet>
  </div>

  <div v-else class="d-flex justify-center">
    <v-sheet width="350" class="bg-color">
      <v-img
        @contextmenu.prevent
        src="@/assets/04_ilustration_no_product_found.svg"
        lazy-src="@/assets/04_ilustration_no_product_found.svg"
        width="400"
      ></v-img>
      <p class="text-h6 font-weight-bold text-center">
        Maaf, Kami Tidak Menemukan Yang Kamu Cari
      </p>
      <p class="text-body-2 text-center">
        Silahkan ketik kata kunci lainnya di kolom pencarian
      </p>
    </v-sheet>
  </div>

  <filter-product
    :filterEventDate="false"
    :locationData="locationData"
    v-model:dialog="dialog.state"
    @filtering="filtering"
  ></filter-product>
</template>

<script>
import EventCard from "@/components/Cards/EventCard.vue";
import FunCard from "@/components/Cards/FunCard.vue";
import WorkshopCard from "@/components/Cards/WorkshopCard.vue";
import UpcomingCard from "@/components/Cards/UpcomingCard.vue";
import NonEventCard from "@/components/Cards/NonEventCard.vue";
import FilterProduct from "@/components/FilterProduct.vue";
import { CATEGORY } from "@/config/category";

export default {
  name: "SearchView",

  components: {
    EventCard,
    FunCard,
    WorkshopCard,
    UpcomingCard,
    NonEventCard,
    FilterProduct,
  },

  computed: {
    getQuery() {
      return this.$route.query?.q;
    },
    loadingState() {
      return this.loading && this.search.loading;
    },
  },

  data() {
    return {
      paginationLength: 10,
      filter: {
        category: -1,
        date: null,
        priceLow: null,
        priceHigh: null,
        city: "",
      },
      query: "",
      search: {
        result: [],
        isLoading: true,
        error: false,
      },
      loading: false,
      rules: {
        filterPrice: [
          (value) => {
            return (
              !value[0] ||
              !value[1] ||
              parseInt(value[0]) <= parseInt(value[1]) ||
              "Harga minimum lebih besar dari maximum"
            );
          },
        ],
      },
      dialog: {
        state: false,
        filter: {
          category: null,
          date: null,
          price: [null, null],
          location: [],
        },
      },
      sort: [
        { name: "Terbaru", state: { order: "desc" } },
        { name: "Harga Terendah", state: { sort: "price", order: "asc" } },
        { name: "Harga Tertinggi", state: { sort: "price", order: "desc" } },
      ],
      sortSelection: {
        name: "Terbaru",
        state: { order: "desc" },
      },
      min: 0,
      max: 100_000_000,
      items: [
        {
          title: "Beranda",
          disabled: false,
          href: "/",
        },
        {
          title: "Hasil Pencarian",
          disabled: true,
          href: "/",
          color: "primary",
        },
      ],
      chips: [
        {
          productcategory_id: -1,
          productcategory_name: "Semua",
        },
      ],
      searchData: {
        productName: "",
        pageSize: 60,
        page: 1,
      },
      locationData: [
        "Jakarta",
        "Bekasi",
        "Surabaya",
        "Medan",
        "Bandung",
        "Depok",
        "Bali",
        "Palembang",
        "Yogyakarta",
        "Tangerang",
        "Makassar",
        "Balikpapan",
        "Semarang",
        "Cikarang",
        "Padang",
      ],
    };
  },

  watch: {
    "dialog.filter.price": {
      deep: true,
      async handler() {
        this.$refs.filterPrice.validate();
      },
    },
    "filter.category"() {
      this.refreshProduct();
    },
    searchData: {
      deep: true,
      handler() {
        this.refreshProduct();
      },
    },
    getQuery(newData) {
      this.searchData = {
        productName: newData || null,
        pageSize: 60,
        page: 1,
      };
      this.filter = {
        category: -1,
        date: null,
        priceLow: null,
        priceHigh: null,
        city: "",
      };

      this.sortSelection = {
        name: "Terbaru",
        state: { order: "desc" },
      };

      this.getSearchProduct(this.searchData);
    },
    sortSelection: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.searchData["sort"] = newValue.sort;
          this.searchData["order"] = newValue.order;
          this.refreshProduct();
        }
      },
    },
  },

  async created() {
    this.searchData.productName = (await this.$route.query.q) || null;

    this.searchData["sort"] = this.sortSelection.state.sort;
    this.searchData["order"] = this.sortSelection.state.order;
    this.getSearchProduct(this.searchData);

    this.getAllCategory();

    this.axios.get("/product/own/city").then((response) => {
      this.locationData = response.data.response.map(
        (city) => city.locationcity_name
      );
    });
  },

  methods: {
    refreshProduct() {
      const category = this.filter.category;

      this.getSearchProduct({
        productName: this.searchData.productName,
        pageSize: this.searchData.pageSize,
        page: this.searchData.page,
        sortby: this.searchData.sort,
        order: this.searchData.order,
        priceHigh: this.filter.priceHigh || null,
        priceLow: this.filter.priceLow || null,
        category: category != -1 ? category : null,
        city: this.filter.city || null,
      });
    },
    getSearchProduct({
      productName,
      pageSize,
      page,
      priceLow,
      priceHigh,
      category,
      sortby,
      order,
      city,
    }) {
      this.search.isLoading = true;
      this.axios
        .get("product", {
          params: {
            product_name: productName,
            pageSize,
            page,
            priceLow,
            priceHigh,
            category,
            sortby,
            order,
            city,
          },
        })
        .then((response) => {
          const data = response.data.response;
          const products = data.products;

          const soldOut = products.filter((product) => {
            if (product.quantity <= 0 && product.is_limited) {
              return product;
            }
          });
          const ready = products.filter((product) => {
            if (product.quantity > 0 || !product.is_limited) {
              return product;
            }
          });

          this.search.result = [...ready, ...soldOut];
          this.paginationLength = Math.ceil(
            data.count / this.searchData.pageSize
          );
        })
        .finally(() => (this.search.isLoading = false));
    },
    async getAllCategory() {
      this.loading = true;
      await this.axios
        .get("product/categories")
        .then((response) => {
          this.loading = false;
          const categories = response.data.response;
          const filteredCategories = categories.filter((category) =>
            [
              CATEGORY.HEALING,
              CATEGORY.TAMAN_HIBURAN,
              CATEGORY.KONSER,
              CATEGORY.RACE,
            ].includes(category.productcategory_id)
          );

          this.chips.push(...filteredCategories);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    filtering(newValue) {
      if (newValue.price.length) {
        this.filter.priceLow = newValue.price[0];
        this.filter.priceHigh = newValue.price[1];
      }
      this.filter.city = newValue.location.join(",");
      this.filter.startDate = newValue.startDate;
      this.filter.endDate = newValue.endDate;
      this.refreshProduct();
    },
  },
};
</script>

<style scoped>
.size-select {
  max-width: 10rem;
}
</style>